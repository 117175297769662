/* eslint-disable camelcase */
import { useStaticValue } from "../react";
import { childrenToItems } from "./children-to-items";
import type { BaseCollectionItem, CollectionProps } from "./types";
import {
  useCollectionWarnings,
  useDynamicCollectionWarnings,
  useStaticCollectionWarnings,
} from "./warnings";

/* Here's a simplified version of what the hook does without the noise.

export function useCollection({ items, children }) {
  if (items) return items;
  const [itemsFromChildren] = useState(() => childrenToItems(children));
  return itemsFromChildren;
}

*/

/** Used to build collection components, by processing the `items` and
 * `children` props and creating a memoized array of items. */
export function useCollection<T extends BaseCollectionItem>(
  props: CollectionProps<T>,
  dynamicCollectionHookName = "useMemo"
): T[] {
  const { items, children } = props;
  // keep the returned value as stable as possible in case of missing or empty input
  const emptyItems = useStaticValue<T[]>(() => []);

  if (process.env.NODE_ENV === "development")
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useCollectionWarnings({ items, children });

  if (items) {
    if (process.env.NODE_ENV === "development")
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useDynamicCollectionWarnings(
        items,
        dynamicCollectionHookName,
        props.UNSAFE_disableDynamicCollectionWarnings
      );

    return items.length > 0 ? items : emptyItems;
  }

  if (!children) return emptyItems;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const itemsFromChildren = useStaticValue(() => childrenToItems<T>(children));

  if (process.env.NODE_ENV === "development")
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useStaticCollectionWarnings({ children, itemsFromChildren });

  return itemsFromChildren.length > 0 ? itemsFromChildren : emptyItems;
}

type InferItemTypeFromProps<T> = T extends CollectionProps<infer U> ? U : never;

/** Used to build collection components, by processing the `items` and
 * `children` props and creating a memoized array of items.
 *
 * Automatically extracts and uses the relevant props. Returns a tuple:
 *
 * - First value: the created collection.
 * - Second value: the rest of the props. */
export function usePCollection<
  P extends CollectionProps<BaseCollectionItem>,
  T = InferItemTypeFromProps<P>
  // TODO: make dynamicCollectionHookName required once all hooks are built
>(props: P, dynamicCollectionHookName = "useMemo") {
  const {
    items,
    children,
    UNSAFE_disableDynamicCollectionWarnings,
    ...restProps
  } = props;
  const collectionItems = useCollection(
    props,
    dynamicCollectionHookName
    // TODO: for some reason it's impossible to have generic props and
    // generic item type at the same time here, so this is a workaround
  ) as unknown as T[];
  return [collectionItems, restProps] as const;
}
