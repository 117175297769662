/* eslint-disable import/prefer-default-export */
import "./OptionSectionHeading.sass";

import clsx from "clsx";

import { createComponentUtils } from "../__utils/atlas";
import type { AtlasOptionSectionHeadingComponent } from "./types";

// config
// ------

const COMPONENT_NAME = "OptionSectionHeading";

const { ROOT, createComponent } = createComponentUtils(COMPONENT_NAME);

// option separator
// ----------------

/**
 * A component that renders an option section heading. This is a low-level component, and
 * in most cases it shouldn't be used directly.
 */
export const OptionSectionHeading =
  createComponent<AtlasOptionSectionHeadingComponent>((props) => (
    <div {...props} className={clsx(ROOT, props.className)} />
  ));
