import { CompositeState } from "ariakit";
import { ReactElement, RefObject, useCallback, useRef } from "react";
import { defaultRangeExtractor, Range, useVirtual } from "react-virtual";

import {
  BaseCollectionItem,
  CollectionItemRenderers,
  renderCollectionItem,
  useRenderCollection,
} from "./collections";
import { useId } from "./react";

// shared types
// ------------

type BaseListRendererProps<Item extends BaseCollectionItem> = {
  items: Item[];
  renderers: CollectionItemRenderers<Item>;
};

// non virtual
// -----------

type NonVirtualListRendererProps<Item extends BaseCollectionItem> =
  BaseListRendererProps<Item>;

function NonVirtualListRenderer<Item extends BaseCollectionItem>({
  items,
  renderers,
}: NonVirtualListRendererProps<Item>) {
  return <>{useRenderCollection(items, renderers)}</>;
}

// virtual
// -------

export type VirtualCompositeOptions<Item extends BaseCollectionItem> = {
  compositeState: CompositeState;
  compositeItemTypes: Item["_type"][];
  estimateSize?: (item: Item) => number;
  isDynamicallySized?: (item: Item) => boolean;
  getCustomPinnedIndexes?: GetCustomPinnedIndexes;
};

type VirtualListRendererOwnProps<Item extends BaseCollectionItem> = {
  virtualCompositeOptions: VirtualCompositeOptions<Item>;
};

type VirtualListRendererProps<Item extends BaseCollectionItem> =
  BaseListRendererProps<Item> &
    VirtualListRendererOwnProps<Item> & {
      listRef: RefObject<unknown>;
    };

function extractItemKey<Item extends BaseCollectionItem>(item: Item) {
  const { key } = item as { key?: string };
  if (key == null)
    throw new Error("All items in a virtualized list must have a key");
  return key;
}

function idToIndex(id: string) {
  return +id.split("-")[1];
}

function findClosestCompositeItemIndex<Item extends BaseCollectionItem>(
  items: Item[],
  compositeItemTypes: Item["_type"][],
  fromIndex: number,
  direction: "backwards" | "forwards"
): number | undefined {
  let i = fromIndex;
  while (i >= 0 && i < items.length) {
    // eslint-disable-next-line no-underscore-dangle
    if (compositeItemTypes.includes(items[i]._type)) return i;
    i += direction === "backwards" ? -1 : 1;
  }
  return undefined;
}

export type GetCustomPinnedIndexes = (
  idToIndexFn: typeof idToIndex
) => number[];

function getPinnedIndexes<Item extends BaseCollectionItem>(
  items: Item[],
  compositeItemTypes: Item["_type"][],
  activeId?: string | null,
  getCustomPinnedIndexes?: GetCustomPinnedIndexes
) {
  const pinnedIndexes: number[] = [];
  const pushIfNumber = (n: unknown) => {
    if (typeof n === "number") pinnedIndexes.push(n);
  };
  function findClosest(fromIndex: number, direction: "backwards" | "forwards") {
    return findClosestCompositeItemIndex(
      items,
      compositeItemTypes,
      fromIndex,
      direction
    );
  }

  const activeItem = activeId ? idToIndex(activeId) : undefined;

  // active item
  pushIfNumber(activeItem);
  if (activeItem !== undefined) {
    // previous item
    pushIfNumber(findClosest(activeItem - 1, "backwards"));
    // next item
    pushIfNumber(findClosest(activeItem + 1, "forwards"));
  }
  // first item
  pushIfNumber(findClosest(0, "forwards"));
  // last item
  pushIfNumber(findClosest(items.length - 1, "backwards"));

  const extraIndexes = getCustomPinnedIndexes?.(idToIndex);
  if (extraIndexes) pinnedIndexes.push(...extraIndexes);

  return pinnedIndexes;
}

function VirtualListRenderer<Item extends BaseCollectionItem>({
  items,
  renderers,
  listRef,
  virtualCompositeOptions: {
    compositeState,
    estimateSize,
    isDynamicallySized,
    compositeItemTypes,
    getCustomPinnedIndexes,
  },
}: VirtualListRendererProps<Item>) {
  const estimateItemSize = useCallback(
    (i: number) => (estimateSize ? estimateSize(items[i]) : 0),
    [estimateSize, items]
  );
  const rowVirtualizer = useVirtual({
    size: items.length,
    parentRef: listRef,
    overscan: 10,
    estimateSize: estimateSize ? estimateItemSize : undefined,
    keyExtractor: useCallback((i: number) => extractItemKey(items[i]), [items]),
    rangeExtractor: useCallback(
      (range: Range) => {
        const indexes: number[] = [];
        const defaultIndexes = defaultRangeExtractor(range);

        getPinnedIndexes(
          items,
          compositeItemTypes,
          compositeState.activeId,
          getCustomPinnedIndexes
        ).forEach((pinnedIndex) => {
          if (
            // prevent duplicates
            !(
              defaultIndexes.includes(pinnedIndex) ||
              indexes.includes(pinnedIndex)
            )
          )
            indexes.push(pinnedIndex);
        });

        indexes.push(...defaultIndexes);

        return indexes.sort((a, b) => a - b);
      },
      [
        compositeItemTypes,
        compositeState.activeId,
        getCustomPinnedIndexes,
        items,
      ]
    ),
  });

  const idPrefix = useId();

  return (
    <div
      role="presentation"
      style={{
        height: rowVirtualizer.totalSize,
        position: "relative",
      }}
    >
      {rowVirtualizer.virtualItems.map((row) => {
        // TODO: figure out why we're getting an empty item
        if (row.index === undefined) return undefined;

        const item = items[row.index];

        const htmlProps = {
          key: row.key,
          ref:
            !isDynamicallySized || isDynamicallySized(item)
              ? row.measureRef
              : undefined,
          id: `${idPrefix}-${row.index}`,
          style: {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            transform: `translateY(${row.start}px)`,
          },
        };

        return renderCollectionItem(
          [{ ...item, ...htmlProps }, row.index, items],
          renderers
        );
      })}
    </div>
  );
}

// list renderer
// -------------

type ListRendererProps<Item extends BaseCollectionItem> =
  BaseListRendererProps<Item> &
    VirtualListRendererOwnProps<Item> & {
      isVirtual?: boolean;
      className?: string;
    };

export function CompositeListRenderer<Item extends BaseCollectionItem>({
  className,
  items,
  renderers,
  isVirtual,
  virtualCompositeOptions,
}: ListRendererProps<Item>): ReactElement {
  const listRef = useRef<HTMLDivElement>(null);

  return (
    <div ref={listRef} className={className} role="presentation">
      {isVirtual ? (
        <VirtualListRenderer
          items={items}
          renderers={renderers}
          listRef={listRef}
          virtualCompositeOptions={virtualCompositeOptions}
        />
      ) : (
        <NonVirtualListRenderer items={items} renderers={renderers} />
      )}
    </div>
  );
}
