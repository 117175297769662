/* eslint-disable import/prefer-default-export */
import "./OptionGroupLabel.sass";

import clsx from "clsx";

import { createComponentUtils } from "../__utils/atlas";
import type { AtlasOptionGroupLabelComponent } from "./types";

// config
// ------

const COMPONENT_NAME = "OptionGroupLabel";

const { ROOT, createComponent } = createComponentUtils(COMPONENT_NAME);

// option separator
// ----------------

/**
 * A component that renders an option group label. This is a low-level component, and
 * in most cases it shouldn't be used directly.
 */
export const OptionGroupLabel = createComponent<AtlasOptionGroupLabelComponent>(
  (props) => <div {...props} className={clsx(ROOT, props.className)} />
);
