import { CompositeState } from "ariakit";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useIsomorphicLayoutEffect } from "react-use";

/** @deprecated Already fixed in Ariakit. */
function useScheduleFocus(activeElement?: HTMLElement) {
  const [scheduled, setScheduled] = useState(false);
  const schedule = useCallback(() => setScheduled(true), []);
  useEffect(() => {
    if (scheduled && activeElement) {
      setScheduled(false);
      activeElement.scrollIntoView({ block: "nearest" });
      activeElement.focus({ preventScroll: true });
    }
  }, [activeElement, scheduled]);
  return schedule;
}

/** Implements a a workaround for Ariakit composites by replacing the focus
 * and scroll behavior.
 *
 * Might be fixed in Ariakit soon: https://github.com/ariakit/ariakit/pull/1165
 *
 * Must be used in combination with `scrollWorkaroundCompositeProps`.
 * @deprecated Already fixed in Ariakit.
 */
export function useCompositeScrollWorkaround<S extends CompositeState>(
  state: S
) {
  const activeElement = useMemo(
    () =>
      state.activeId
        ? document.getElementById(state.activeId) ?? undefined
        : undefined,
    [state.activeId]
  );
  const scheduleFocus = useScheduleFocus(activeElement);
  useIsomorphicLayoutEffect(() => {
    if (!activeElement) return;
    scheduleFocus();
  }, [state.moves]);
}

/** Props to be passed to composites.
 *
 * Must be used in combination with `useCompositeScrollWorkaround`.
 * @deprecated Already fixed in Ariakit
 */
export const scrollWorkaroundCompositeProps = { focusOnMove: false };
