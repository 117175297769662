/* eslint-disable no-underscore-dangle */
import { ReactNode, useMemo } from "react";

import type { BaseCollectionItem } from "./types";

type RendererTypeArgs<T, A = T> = [
  value: T,
  index: number,
  array: readonly A[]
];

/** A renderer for a collection item type. */
export type CollectionItemRenderer<T extends BaseCollectionItem, A = T> = (
  ...args: RendererTypeArgs<Omit<T, "_type">, A>
) => ReactNode;

/** A set of renderers by item type for a collection. */
export type CollectionItemRenderers<T extends BaseCollectionItem> = {
  [Type in T["_type"]]: CollectionItemRenderer<T & { _type: Type }, T>;
};

function excludeTypeProperty<T extends BaseCollectionItem>(item: T) {
  const { _type, ...rest } = item;
  return rest;
}

/** Utility that renders a collection item by passing a map-like input and renderers
 * for each item type. */
export function renderCollectionItem<T extends BaseCollectionItem>(
  /** Input containing the collection item to render and map-like args. */
  [item, ...rest]: RendererTypeArgs<T>,
  /** Functions used to render each item by type, passed as callbacks to `.map()`. */
  renderers: CollectionItemRenderers<T>
) {
  const renderer = renderers[item._type as T["_type"]];

  if (!renderer)
    throw new Error(`Missing renderer for item type "${item._type}"`);

  return renderer(excludeTypeProperty(item), ...rest);
}

/** Utility that renders a collection by passing renderers for each item type. */
export function useRenderCollection<T extends BaseCollectionItem>(
  /** Collection items to render. */
  items: T[],
  /** **Must be memoized.**
   *
   * Functions used to render each item by type, passed as callbacks to `.map()`. */
  renderers: CollectionItemRenderers<T>
) {
  return useMemo(
    () => items.map((...input) => renderCollectionItem(input, renderers)),
    [items, renderers]
  );
}
