import { createChildRenderFunction, RenderProps } from "../../__utils/atlas";
import { OptionItem } from "../OptionItem";
import type { AtlasOptionItemProps } from "../types";
import { extractOptionItemOptions } from "./extract-option-item-options";

// types
// -----

export type AtlasOptionItemRenderProps = RenderProps<
  "OptionItem",
  [optionProps: AtlasOptionItemProps]
>;

// create option item render function
// ----------------------------------

/** Creates a render function to be used by a component that extends `OptionItem`,
 * and also returns the props that are not `OptionItem` options. */
export function createOptionItemRenderFunction<P>(
  p0: AtlasOptionItemProps & AtlasOptionItemRenderProps & P
) {
  const [optionItemOptions, props] = extractOptionItemOptions(p0);

  const { children } = props;

  const optionItemProps: AtlasOptionItemProps = {
    ...optionItemOptions,
    // pass children too for simplicity
    children,
  };

  const renderOptionItem = createChildRenderFunction({
    props,
    args: [optionItemProps],
    name: "OptionItem",
    originalElement: <OptionItem {...optionItemProps}>{children}</OptionItem>,
  });

  return [renderOptionItem, props] as const;
}
