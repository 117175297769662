/* eslint-disable import/prefer-default-export */
import {
  CompositeStateProps,
  PopoverStateProps,
  useComboboxState as useAriakitComboboxState,
  useMenuState as useAriakitMenuState,
} from "ariakit";
import { useMemo } from "react";

import {
  SHARED_COMPOSITE_STATE_DEFAULTS,
  SHARED_POPOVER_STATE_DEFAULTS,
} from "../__utils/atlas";
import { createDefaultProps, useStaticValueError } from "../__utils/react";
import type { AtlasMenuState, AtlasMenuStateProps } from "./types";

const DEFAULT_PROPS = createDefaultProps<AtlasMenuStateProps>()({
  ...SHARED_POPOVER_STATE_DEFAULTS,
  ...SHARED_COMPOSITE_STATE_DEFAULTS,
} as const);

/**
 * Creates a menu state.
 *
 * @example
 * const menu = useMenuState();
 * return <Menu.Root state={menu}>...</Menu.Root>;
 */
export function useMenuState({
  placement = DEFAULT_PROPS.placement,
  animated = DEFAULT_PROPS.animated,
  gutter = DEFAULT_PROPS.gutter,
  overflowPadding = DEFAULT_PROPS.overflowPadding,
  focusLoop = DEFAULT_PROPS.focusLoop,
  searchable = false,
  comboboxProps,
  ...props
}: AtlasMenuStateProps = {}) {
  useStaticValueError(
    searchable,
    'The value for "searchable" cannot change after the initial render of Menu'
  );

  // passed to either Menu or Combobox, depending on the value of searchable
  const baseStateProps: CompositeStateProps & PopoverStateProps = {
    placement,
    animated,
    gutter,
    overflowPadding,
    focusLoop,
    ...props,
  };

  const combobox = useAriakitComboboxState(
    // the combobox state is not used unless searchable
    searchable
      ? { includesBaseElement: false, ...baseStateProps, ...comboboxProps }
      : undefined
  );

  // if searchable, we need to sync the menu state with the combobox state
  const ariakitMenu = useAriakitMenuState(
    searchable ? combobox : baseStateProps
  );

  const menu: AtlasMenuState = useMemo(
    () => ({ ...ariakitMenu, searchable, combobox }),
    [ariakitMenu, combobox, searchable]
  );

  return menu;
}
