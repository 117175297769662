import { isValidElement, ReactChild, ReactNode } from "react";
import flattenChildren from "react-keyed-flatten-children";

import { getComponentMetadata } from "../atlas";
import { AnyRefAttributes } from "../html";
import { BaseCollectionItem } from ".";

export function childToItem<T extends BaseCollectionItem>(
  child: ReactChild
): T {
  // check for invalid elements
  if (!isValidElement(child) || typeof child?.type === "string")
    throw new Error("Invalid child in static collection.");

  // check for missing collection type metadata
  const { collectionType } = getComponentMetadata(child.type);
  if (!collectionType)
    throw new Error("Collection metadata missing in element.");

  // return the element data as a collection item
  return {
    _type: collectionType,
    // copy the key over
    key: child.key,
    // as well as the ref
    ref: (child as AnyRefAttributes).ref,
    // finally, copy the props
    ...child.props,
  } as T;
}

/** "Parse" the children of a collection component and turn them into items (as data). */
export function childrenToItems<T extends BaseCollectionItem>(
  children: ReactNode
): T[] {
  const flattenedChildren = flattenChildren(children).filter(Boolean);
  return flattenedChildren.map((child) => childToItem(child));
}
