/* eslint-disable import/prefer-default-export */

import { createOptionsExtractor } from "../../__utils/misc";
import { AtlasOptionItemOptions } from "../types";

/** Separates option item options from other props. */
export const extractOptionItemOptions =
  createOptionsExtractor<AtlasOptionItemOptions>([
    "size",
    "isSelectable",
    "isSelected",
    "isDisabled",
    "isDestructive",
    "renderContent",
    "leadingContent",
    "trailingContent",
    "highlights",
    "disableInteractivity",
  ] as const);
