/* eslint-disable import/prefer-default-export */
import "./OptionItem.sass";

import parse from "autosuggest-highlight/parse";
import clsx from "clsx";
import { useMemo } from "react";

import { atlasCheck } from "../../icons";
import { createComponentUtils } from "../__utils/atlas";
import { createDefaultProps } from "../__utils/react";
import { Icon } from "../icon";
import type { AtlasOptionItemComponent, AtlasOptionItemProps } from "./types";
import { extractOptionItemOptions } from "./utils/extract-option-item-options";

// config
// ------

const COMPONENT_NAME = "OptionItem";

const DEFAULT_PROPS = createDefaultProps<AtlasOptionItemProps>()({
  size: "default",
} as const);

const SELECTED_ICON = atlasCheck;

const HIGHLIGHTED_TEXT_WEIGHT = 600;

const { ROOT, el, createComponent } = createComponentUtils(COMPONENT_NAME);

// option item
// -----------

type HighlightData = ReturnType<typeof parse>;

function renderHighlightedText(highlights: HighlightData) {
  return highlights.map((part, i) => (
    <span
      // eslint-disable-next-line react/no-array-index-key
      key={`${part.text}-${i}`}
      style={
        part.highlight ? { fontWeight: HIGHLIGHTED_TEXT_WEIGHT } : undefined
      }
    >
      {part.text}
    </span>
  ));
}

/**
 * A component that renders an option item. This is a low-level component, and
 * in most cases it shouldn't be used directly.
 */
export const OptionItem = createComponent<AtlasOptionItemComponent>((p0) => {
  const [optionItemOptions, p1] = extractOptionItemOptions(p0);
  const { children, ...props } = p1;

  const {
    size = DEFAULT_PROPS.size,
    highlights,
    isSelectable,
    isSelected,
    isDisabled,
    isDestructive,
    renderContent,
    leadingContent,
    trailingContent,
    disableInteractivity,
  } = optionItemOptions;

  // create highlighted text or return the original children
  const childrenWithHighlights = useMemo(() => {
    // only created if `children` is a string
    if (highlights && typeof children === "string")
      return <span>{renderHighlightedText(parse(children, highlights))}</span>;
    return children;
  }, [children, highlights]);

  return (
    <div
      {...props}
      className={clsx(
        ROOT,
        `size-${size}`,
        {
          isSelectable,
          isSelected,
          isDisabled,
          isDestructive,
          disableInteractivity,
        },
        props.className
      )}
    >
      {isSelectable && (
        <div className={el`selectable-container`}>
          <Icon content={SELECTED_ICON} />
        </div>
      )}
      <div className={el`content-container`}>
        {leadingContent && <div>{leadingContent}</div>}
        <div className={el`content`}>
          {renderContent ? (
            renderContent(
              { children, childrenWithHighlights },
              optionItemOptions
            )
          ) : (
            <p className={el`default-content`}>{childrenWithHighlights}</p>
          )}
        </div>
        {trailingContent && <div>{trailingContent}</div>}
      </div>
    </div>
  );
});
