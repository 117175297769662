/* eslint-disable import/prefer-default-export */

import type { HTMLAttributes } from "../../__utils/html";
import { createOptionItemRenderFunction } from "../../option/utils";
import type { AtlasMenuItemProps } from "../types";

/** Returns the props to be passed to the Ariakit `MenuItem` component. */
export function getItemProps(p0: AtlasMenuItemProps): HTMLAttributes<"div"> {
  const [children, props] = createOptionItemRenderFunction({
    ...p0,
    // TODO: maybe if we drop the "is" prefix convention this won't be necessary
    isDisabled: p0.disabled,
  });

  return {
    ...props,
    // TODO: remove onSelect (onClick is enough)
    onClick:
      props.onClick || props.onSelect
        ? (event) => {
            props.onClick?.(event);
            if (!event.defaultPrevented) props.onSelect?.();
          }
        : undefined,
    children,
  };
}
