/* eslint-disable import/prefer-default-export */
import clsx from "clsx";

import { createComponentUtils } from "../__utils/atlas";
import { createDefaultProps } from "../__utils/react";
import { LoadingIndicator } from "../loading-indicator";
import { AtlasFloatingCardComponent, AtlasFloatingCardProps } from "./types";

// config
// ------

const COMPONENT_NAME = "FloatingCard";

const DEFAULT_PROPS = createDefaultProps<AtlasFloatingCardProps>()({
  emptyText: "No results",
} as const);

const { ROOT, el, createComponent } = createComponentUtils(COMPONENT_NAME);

// floating card
// -------------

/**
 * A low-level component used to render floating cards in other
 * components such as `Popover`, `Menu`, `Select`...
 *
 * **Depends on the following requirements to function correctly:**
 *
 * - For sizing, the following CSS variables must be available:
 *   - `--popover-overflow-padding`: the distance to leave from the
 *     edge of the card to the edge of the viewport.
 *   - `--popover-available-height`: the maximum height that the
 *     card can have without overflowing the viewport (taking
 *     the overflow padding into account).
 * - For entry/exit animations, the `data-enter` attribute must be
 *   added or removed accordingly, and the `data-placement` attribute
 *   must be set with the current placement value (e.g. `bottom-start`).
 *
 * Note that, in most instances, Ariakit will provide the CSS
 * variables and the `data-enter` attribute for you. The
 * `data-placement` attribute, however, must be passed manually
 * by the parent component.
 */
export const FloatingCard = createComponent<AtlasFloatingCardComponent>(
  ({
    emptyText = DEFAULT_PROPS.emptyText,
    header,
    footer,
    bodyProps,
    isLoading,
    isEmpty,
    sameWidth,
    children,
    ...props
  }) => (
    <div {...props} className={clsx(ROOT, { sameWidth }, props.className)}>
      {header && <div className={el`header`}>{header}</div>}
      <div {...bodyProps} className={clsx(el`body`, bodyProps?.className)}>
        {/* eslint-disable-next-line no-nested-ternary */}
        {isLoading ? (
          <div className={el`loading`}>
            <LoadingIndicator size="small" />
          </div>
        ) : isEmpty ? (
          <p className={el`empty-state`}>{emptyText}</p>
        ) : (
          children
        )}
      </div>
      {footer && <div className={el`footer`}>{footer}</div>}
    </div>
  )
);
