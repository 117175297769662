import { MenuItem, MenuSeparator } from "ariakit";
import { useMemo } from "react";

import { createComponentUtils, createRootContext } from "../../__utils/atlas";
import { CollectionItemRenderers } from "../../__utils/collections";
import {
  AtlasOptionItemProps,
  OptionGroupLabel,
  OptionSectionHeading,
  OptionSeparator,
} from "../../option";
import type {
  AtlasMenuContentItem,
  AtlasMenuContentProps,
  AtlasMenuGroupLabelComponent,
  AtlasMenuItemComponent,
  AtlasMenuSectionHeadingComponent,
  AtlasMenuSeparatorComponent,
  AtlasMenuState,
} from "../types";
import { getItemProps } from "./item";

// config
// ------

const COMPONENT_NAME = "Menu";

export const { el, createComponent } = createComponentUtils(COMPONENT_NAME);

// context
// -------

export type MenuRootContext = {
  menu: AtlasMenuState;
};

export const { RootProvider, useRootContext } =
  createRootContext<MenuRootContext>(COMPONENT_NAME);

// list props
// ----------

export type BaseListProps = {
  items: AtlasMenuContentItem[];
  isVirtual?: boolean;
  defaultSize?: AtlasMenuContentProps["defaultSize"];
};

// item
// ----

/**
 * An item in a menu. Must be a child of `<Menu.Content />`.
 *
 * @example
 * <Menu.Item>Settings</Menu.Item>
 */
export const Item = createComponent<AtlasMenuItemComponent>(
  (props) => <MenuItem {...getItemProps(props)} />,
  { treeName: "Item", metadata: { collectionType: "item" } }
);

// separator
// ---------

/**
 * A separator in a menu. Must be a child of `<Menu.Content />`.
 *
 * @example
 * <Menu.Separator />
 */
export const Separator = createComponent<AtlasMenuSeparatorComponent>(
  (props) => (
    <MenuSeparator {...props}>
      {(separatorProps) => <OptionSeparator {...separatorProps} />}
    </MenuSeparator>
  ),
  { treeName: "Separator", metadata: { collectionType: "separator" } }
);

// group label
// -----------

/**
 * A group label in a menu. Must be a child of `<Menu.Content />`.
 *
 * @example
 * <Menu.GroupLabel />
 */
export const GroupLabel = createComponent<AtlasMenuGroupLabelComponent>(
  (props) => <OptionGroupLabel {...props} />,
  { treeName: "GroupLabel", metadata: { collectionType: "groupLabel" } }
);

// section heading
// ---------------

/**
 * A section heading in a menu. Must be a child of `<Menu.Content />`.
 *
 * @example
 * <Menu.SectionHeading />
 */
export const SectionHeading = createComponent<AtlasMenuSectionHeadingComponent>(
  (props) => <OptionSectionHeading {...props} />,
  {
    treeName: "SectionHeading",
    metadata: { collectionType: "sectionHeading" },
  }
);

// menu renderers
// --------------

type UseMenuRenderersOptions = {
  defaultSize?: NonNullable<AtlasOptionItemProps["size"]>;
  overrideRenderers?: Partial<CollectionItemRenderers<AtlasMenuContentItem>>;
};

export function useMenuRenderers({
  defaultSize,
  overrideRenderers,
}: UseMenuRenderersOptions) {
  const renderers: CollectionItemRenderers<AtlasMenuContentItem> = useMemo(
    () => ({
      item: (props) => <Item size={defaultSize} {...props} />,
      separator: (props) => <Separator {...props} />,
      groupLabel: (props) => <GroupLabel {...props} />,
      sectionHeading: (props) => <SectionHeading {...props} />,
      ...overrideRenderers,
    }),
    [defaultSize, overrideRenderers]
  );

  return renderers;
}
